export default [
  {
    header: 'Accessibility',
  },
  // {
  //   title: 'Chat',
  //   route: 'apps-chats',
  //   icon: 'MessageCircleIcon',
  //   resource: 'Support',
  //   action: 'Support',
  // },
  {
    title: 'Support',
    icon: 'HeadphonesIcon',
    route: 'apps-allTickets',
  },
  {
    title: 'Currencies',
    icon: 'DollarSignIcon',
    route: 'apps-currencies-manage-currencies',
  },
  {
    title: 'Game Reviews',
    icon: 'Edit2Icon',
    route: 'apps-game-reviews-list',
  },
  {
    title: 'Games',
    icon: 'ApertureIcon',
    children: [
      {
        title: 'Create Game',
        route: 'apps-games-create-game',
      },
      {
        title: 'Manage Games',
        route: 'apps-games-manage-games',
      },
      {
        title: 'Platforms',
        route: 'apps-games-manage-platforms'
      },
      {
        title: 'Offers',
        route: 'apps-games-manage-offers'
      },
    ],

  },

  {
    title: 'Blogs',
    icon: 'BookOpenIcon',
    // resource: 'Blogger',
    // action: 'Blogger',
    children: [
      {
        title: 'Create',
        route: 'pages-blog-create',
        // resource: 'Blogger',
        // action: 'Blogger',
      },
      {
        title: 'Manage Blogs',
        route: 'pages-blog-list',
        // resource: 'Blogger',
        // action: 'Blogger',
      },

      {
        title: 'Authors Blogs',
        route: 'pages-blog-bloggers-blog-list',
        // resource: 'Blogger',
        // action: 'Blogger',
      },
      // {
      //   title: 'Comments',
      //   route: 'pages-blog-comments',
      //   resource: 'Blogger',
      //   action: 'Blogger',
      // },
    ],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    children: [
      {
        title: 'Manage Users',
        route: 'apps-users-list',
      },
      {
        title: 'Pro Requests',
        route: 'apps-users-pro-lists',
      },
      {
        title: 'Manage Wallets',
        route: 'apps-wallets-manage-wallets',
      },

    ],
  },
  {
    title: 'Orders',
    icon: 'ListIcon',
    children: [
      {
        title: 'All Orders',
        route: 'apps-orders-list',
      },
      {
        title: 'Order Warranties',
        route: 'apps-orders-warranties',
      },

    ],
  },
  {
    title: 'Financial',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Payments List',
        route: 'apps-payments-list',
      },

    ],
  },
  {
    title: 'Faqs',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'Create Faq',
        route: 'apps-site-setting-faqs-create-faq',
      },
      {
        title: 'Manage Faqs',
        route: 'apps-site-setting-faqs-faqs',
      },

    ],
  },
  {
    title: 'Pages',
    icon: 'BookIcon',
    children: [
      {
        title: 'Create Page',
        route: 'apps-site-setting-create-page',
      },
      {
        title: 'Manage pages',
        route: 'apps-site-setting-dynamic-pages',
      },

    ],
  },
  {
    title: 'Coupons',
    icon: 'AwardIcon',
    children: [
      {
        title: 'Create Coupon',
        route: 'apps-site-setting-coupon-create-coupon',
      },
      {
        title: 'Manage Coupons',
        route: 'apps-site-setting-coupon-manage-coupons',
      },

    ],
  },

  {
    title: 'Site Setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Stripe Setting',
        route: 'apps-site-setting-stripe-setting',
      },
      {
        title: 'General Setting',
        route: 'apps-site-setting-general-setting',
      },
    ],
  },

]
